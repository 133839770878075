import { useEffect } from "react";

import { useAtom } from "jotai";
import { LoaderOverlay } from "nid-common";

import { useLocation } from "react-router-dom";

import { appLoaderStateAtom } from "@/contexts/RequestContext";

type Props = {
  children: React.ReactNode;
};

export const AppLoaderProvider: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useAtom(appLoaderStateAtom);

  useEffect(() => {
    // 前ページでLoaderが閉じられていないケース対応
    if (isLoading) {
      setIsLoading(false);
    }
  }, [location]);

  return (
    <>
      {children}
      {isLoading && <LoaderOverlay />}
    </>
  );
};
