import type React from "react";
import { useState } from "react";

import { useAtomValue } from "jotai";
import { useSearchParams } from "react-router-dom";

import { InteractionIdState } from "@/contexts/RequestContext";
import FinishedPage from "@/pages/Dialog/Error/FinishedPage";

export const InnerPage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { issued } = useAtomValue(InteractionIdState);
  const [searchParams] = useSearchParams();
  const existJwtAuth = searchParams.get("auth") !== null;
  const [isValidBrowserSession] = useState(existJwtAuth || !issued);

  return <>{isValidBrowserSession ? children : <FinishedPage />}</>;
};

/**
 * LoginProvider
 * @param children
 * @constructor
 */
export const IssuedWallProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <InnerPage>{children}</InnerPage>;
};
