import React from "react";

import { CheckIcon } from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";
import { CloseIcon } from "../../Icons/CloseIcon/CloseIcon";
import { InfoCircleFillIcon } from "../../Icons/InfoCircleFillIcon/InfoCircleFillIcon";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Nid.AlertMessage";

type variantType = "info" | "danger" | "warning" | "success";

type AlertProps = {
  variant?: variantType;
  className?: string;
  hasIcon?: boolean;
  withBorder?: boolean;
  id?: string;
  testId?: string;
  onDismiss?: () => void;
  children: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
};

type LeadingIconPrrops = {
  variant: variantType | "default";
};

const LeadingIcon = ({ variant }: LeadingIconPrrops) => {
  const icons = {
    info: (
      <InfoCircleFillIcon
        className={`nid-message-icon--${variant}`}
        role="img"
      />
    ),
    danger: (
      <ExclamationTriangleIcon
        className={`nid-message-icon--${variant}`}
        role="img"
        width="20px"
        height="20px"
        id="danger-icon"
        data-testid="danger-icon"
      />
    ),
    warning: (
      <ExclamationCircleIcon
        className={`nid-message-icon--${variant}`}
        role="img"
        width="20px"
        height="20px"
        id="warning-icon"
        data-testid="warning-icon"
      />
    ),
    success: (
      <CheckIcon
        className={`nid-message-icon--${variant}`}
        role="img"
        width="20px"
        height="20px"
        id="success-icon"
        data-testid="success-icon"
      />
    ),
    default: <></>,
  };
  return icons[variant];
};

const AlertMessageComponent = React.forwardRef<HTMLDivElement, AlertProps>(
  (props, ref) => {
    const { hasIcon, withBorder, id, testId, onDismiss, children } = props;
    const variant = props.variant || "default";
    const nidClass = clsx(
      "nid-alert",
      `nid-message-bg--${variant}`,
      { "nid-message-border": withBorder },
      props.className,
    );

    return (
      <Box className={nidClass} id={id} as="div" data-testid={testId} ref={ref}>
        {hasIcon ? (
          <Box className="nid-alert-start">
            <LeadingIcon variant={variant} />
            {children}
          </Box>
        ) : (
          <Box className="nid-alert-start">{children}</Box>
        )}
        {onDismiss && (
          <Box
            as="div"
            role="button"
            className="nid-alert-end"
            onClick={onDismiss}
          >
            <CloseIcon fill="#333333" className="" />
          </Box>
        )}
      </Box>
    );
  },
);

AlertMessageComponent.displayName = DISPLAY_NAME;

const AlertMessage = Object.assign(AlertMessageComponent, {
  Icon: LeadingIcon,
});

const Root = AlertMessage;
export { Root, AlertMessage };
export type { AlertProps as AlertMessageProps };
export default AlertMessage;
