import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

import type { Attributes } from "@/api/attributes";

import type {
  CompanyName,
  ConsentContents,
  PurposeOfUse,
} from "@/api/authorization-confirm";

type AtomWebStorage<T> = {
  key: string;
  default: T;
};

const persistAtom = <T>({ key, default: initialValue }: AtomWebStorage<T>) => {
  return atomWithStorage(
    key,
    initialValue,
    createJSONStorage(() => window.sessionStorage),
    {
      getOnInit: true,
    },
  );
};

export type InteractionIdState = {
  interactionId: string;
  issued: boolean;
};

export type InteractionIdStateType = InteractionIdState;

export const InteractionIdState = persistAtom<InteractionIdStateType>({
  key: "login-interaction-id-state",
  default: {
    interactionId: "",
    issued: false,
  },
});

export const ClientIdState = persistAtom<string>({
  key: "client-id-state",
  default: "",
});

export const loginEmailState = persistAtom<string>({
  key: "login-email",
  default: "",
});

export const fixEmailState = persistAtom<boolean>({
  key: "email-fix",
  default: false,
});

export type AttributesState = {
  required: Array<string>;
  current: Attributes;
};

export const AttributesState = persistAtom<AttributesState>({
  key: "attributes-state",
  default: {
    required: [],
    current: {},
  },
});

export type ConsentState = {
  companyName?: CompanyName;
  privacyPolicyLink?: string;
  contents?: ConsentContents;
  purposeOfUse?: PurposeOfUse;
};

export const ConsentState = persistAtom<ConsentState>({
  key: "consent-state",
  default: {},
});

export const defaultSignupEmailState = persistAtom<string>({
  key: "default-signup-email",
  default: "",
});

export const signupEmailState = persistAtom<string>({
  key: "signup-email",
  default: "",
});

export const mfaSkipState = persistAtom<boolean>({
  key: "login-mfa-skip",
  default: false,
});

export type MfaType = "AUTHENTICATOR_APP_CODE" | "EMAIL_CODE";

export const mfaTypeState = persistAtom<MfaType>({
  key: "login-mfa-type",
  default: "AUTHENTICATOR_APP_CODE",
});

export const SignupFinishedUrlState = persistAtom<string>({
  key: "signup-finished-url",
  default: "",
});

export type UserFlowState = "signup" | "login";

export const userFlowState = persistAtom<UserFlowState>({
  key: "user-flow",
  default: "login",
});

export const userClosedNotificationState = persistAtom<boolean>({
  key: "user-closed-notification",
  default: false,
});

export type Platform = "ios" | "android" | "web";

export const platformState = persistAtom<Platform>({
  key: "platform",
  default: "web",
});

export const sentEmailCodeState = persistAtom<boolean>({
  key: "sent-email-code",
  default: false,
});

export const emailCodeRecipientState = persistAtom<string>({
  key: "email-code-recipient",
  default: "",
});

export const sentSignupOtpState = persistAtom<boolean>({
  key: "sent-signup-otp",
  default: false,
});

export const reservedTokenState = persistAtom<string | undefined>({
  key: "reserved-token",
  default: undefined,
});

export const appLoaderStateAtom = atom<boolean>(false);
