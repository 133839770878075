import type React from "react";

import { DialogLayout } from "nid-common";

import { Loading } from "@/components/ui/Loading";

const LoadingPage: React.FC = () => {
  return (
    <DialogLayout title="loading">
      <Loading />
    </DialogLayout>
  );
};

export default LoadingPage;
