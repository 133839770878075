import i18n from "i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";

import commonJA from "@/locales/ja/common.json";
import loginJA from "@/locales/ja/login.json";
import signupJA from "@/locales/ja/signup.json";

export const ns = ["common", "login", "signup"] as const;
export const resources = {
  ja: {
    common: commonJA,
    login: loginJA,
    signup: signupJA,
  },
} as const;

i18n.use(LanguageDetector).use(initReactI18next).init({
  ns,
  resources,
  fallbackLng: "ja",
});

export default i18n;
