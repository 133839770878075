import { Suspense } from "react";

import { datadogRum } from "@datadog/browser-rum";

import { I18nextProvider } from "react-i18next";
import { RouterProvider, type RouterProviderProps } from "react-router-dom";

import * as config from "./config";

import { HelmetProvider } from "react-helmet-async";
import i18n from "./lib/locale/i18n";
import LoadingPage from "./pages/Dialog/Loading";

// Browser Monitoring
if (config.DATADOG_APPLICATION_ID) {
  datadogRum.init({
    applicationId: config.DATADOG_APPLICATION_ID,
    clientToken: config.DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "nid-melissa-web",
    allowedTracingUrls: [
      /https:\/\/id.*\.nikkei\.com/,
      /https:\/\/id-devn\.id\.n8s\.jp/,
    ],
    env: config.MODE,
    version: `${import.meta.env.VITE_DEPLOYMENT}-${import.meta.env.VITE_APP_VERSION}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    defaultPrivacyLevel: "mask-user-input",
    trackResources: true,
    trackUserInteractions: true,
    beforeSend: (event) => {
      return !(
        event.type === "error" && event?.error.message.includes("csp_violation")
      );
    },
  });
  datadogRum.startSessionReplayRecording();
}

const App = (props: RouterProviderProps) => {
  return (
    <HelmetProvider>
      <Suspense fallback={<LoadingPage />}>
        <I18nextProvider i18n={i18n}>
          <RouterProvider {...props} />
        </I18nextProvider>
      </Suspense>
    </HelmetProvider>
  );
};

export default App;
