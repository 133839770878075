import { Notification as NidNotification } from "nid-common";
import { Link, Typography2 } from "nikkei-ui";
import { Trans, useTranslation } from "react-i18next";

export const Notification = () => {
  const { t } = useTranslation("common");

  return (
    <NidNotification>
      <Typography2>
        <Trans
          t={t}
          i18nKey="message.notification"
          components={[
            <Link
              key="maintenance-details"
              href="/nl/maintenance_notice.html"
              type="text"
              newTab
            />,
          ]}
        />
      </Typography2>
    </NidNotification>
  );
};

export default Notification;
