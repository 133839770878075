import { Box, SkeletonText } from "nikkei-ui";

import styles from "./loading.module.css";

export const Loading = () => {
  return (
    <Box as="section" className={styles.loadingSection}>
      <Box className={styles.loadingBox}>
        <SkeletonText size="small" lineCount={3} />
        <Box className={styles.loadingBoxHalf}>
          <SkeletonText size="small" />
        </Box>
      </Box>
    </Box>
  );
};

Loading.displayName = "Loading";
export default Loading;
