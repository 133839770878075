import React from "react";

import { Provider } from "jotai";
import ReactDOM from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";

import App from "@/App";

import "@/index.css";
import { publicRoutes } from "./routes/public";

const options = { basename: import.meta.env.BASE_URL };
export const router = createBrowserRouter([...publicRoutes], options);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider>
      <App router={router} />
    </Provider>
  </React.StrictMode>,
);
