import { Box, SpinnerIcon } from "nikkei-ui";

export const LoaderOverlay = () => {
  return (
    <Box className="nid-loader-mask" data-testid="loader-overlay">
      <Box className="nid-loader-overlay-spinner">
        <SpinnerIcon width={24} height={24} />
      </Box>
    </Box>
  );
};
