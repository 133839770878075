import type { PathType } from "@/@types/path";

export const path: PathType = {
  login: {
    root: "/login",
    id: "/login/id",
    password: "/login/password",
    mfa: {
      app: "/login/mfa/app",
      email: "/login/mfa/email",
      backupCode: "/login/mfa/backup-code",
    },
    challenge: "/login/challenge",
    registerStablePassword: {
      root: "/login/register-stable-password",
      complete: "/login/register-stable-password-complete",
    },
    attributes: "/login/attributes",
    consent: "/login/consent",
    error: "/login/error",
  },
  signup: {
    start: "/signup/start",
    confirmEmail: "/signup/confirm-email",
    complete: "/signup/complete",
    error: "/signup/error",
  },
};
