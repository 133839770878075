import React from "react";

import {
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  CheckCircleIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import merge from "deepmerge";

import { Box } from "../../../foundations/Box";

import { Text } from "../../../foundations/Text";

import { MailIcon } from "../../Icons/MailIcon/MailIcon";
import { KeyIcon } from "../../Icons/PasswordIcon/KeyIcon";
import { FormControl } from "../FormControl";
import { InputField, type InputFieldProps } from "../InputField";

import type {
  FormValidationStatus,
  LabelVariant,
  Merge,
} from "../../../utils/types";
import type { FormHelperTextProps } from "../FormHelperText";
import type { LabelProps } from "../Label";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Nid.TextField";

type VariantType =
  | "username"
  | "email"
  | "number"
  | "password"
  | "passwordRevealed"
  | "newPassword"
  | "newPasswordRevealed"
  | "mfaCode"
  | "readOnlyText"
  | "userInfo";

type MergeFormTextProps = Merge<FormHelperTextProps, LabelProps>;
type FormTextProps = Omit<MergeFormTextProps, "variant">;

type TextFieldProps = InputFieldProps &
  FormTextProps & {
    status?: FormValidationStatus;
    label?: string;
    withLabelTag?: string;
    variant: VariantType;
    labelVariant?: LabelVariant;
    validationMessage?: string | React.ReactElement;
    leadingIcon?: keyof typeof LeadingIcons;
    trailingIcon?: keyof typeof TrailingIcons;
    onIconClick?: () => void;
    trailingIconLabel?: string;
    // react-hooks-formのwatchを使用する
    hasValue?: boolean; // floating label時に使用
    helperText?: string;
    children?: React.ReactNode;
    testId?: string;
  };

/** Type: number のとき -1e9 のような指数表記も入力可となるため、eキーを無効化する. */
const eKeyPrevent = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (!e.key.match(/\d/)) e.preventDefault();
};

const LeadingIcons = {
  Calendar: <CalendarIcon role="img" className="nid-leading-icon" />,
  CheckCircle: <CheckCircleIcon role="img" className="nid-leading-icon" />,
  Key: <KeyIcon role="img" className="nid-leading-icon nid-icon-key" />,
  Mail: <MailIcon role="img" className="nid-leading-icon nid-icon-email" />,
  Pencil: <PencilIcon role="img" className="nid-leading-icon" />,
  None: undefined,
};

const TrailingIcons = {
  Eye: <EyeIcon role="img" className="trailing-icon" />,
  EyeOff: <EyeSlashIcon role="img" className="trailing-icon" />,
  Pencil: <PencilIcon role="img" className="trailing-icon" />,
  ExternalLink: (
    <ArrowTopRightOnSquareIcon role="img" className="trailing-icon" />
  ),
  None: undefined,
};

type TagProps = React.HTMLAttributes<HTMLSpanElement> & {
  variant?: "default" | "error";
  size?: "small" | "medium" | "large";
  bold?: boolean;
  text: string;
  className?: string;
  icon?: React.ReactNode;
};

const Tag: React.FC<TagProps> = (tagProps) => {
  const { variant, size, bold, className, text, icon } = tagProps;
  const nidClasses = `nid-tag nid-tag-${variant} nid-tag-${size} ${
    bold && "nid-tag-bold"
  } ${className}`;

  const iconNode = icon || null;

  return (
    <Box as={iconNode ? "div" : "span"} className={nidClasses}>
      {iconNode ? (
        <>
          {iconNode}
          <span>{text}</span>
        </>
      ) : (
        text
      )}
    </Box>
  );
};

const params: {
  [key in VariantType]: React.InputHTMLAttributes<HTMLInputElement>;
} =
  // Prototype pollution prevention
  // https://cheatsheetseries.owasp.org/cheatsheets/Prototype_Pollution_Prevention_Cheat_Sheet.html#use-object-freeze-and-seal-mechanisms
  Object.freeze({
    username: {
      type: "email",
      autoComplete: "username",
      placeholder: "Email address",
    },
    email: {
      type: "email",
      autoComplete: "email",
      placeholder: "Email address",
    },
    number: {
      type: "number",
      autoComplete: "off",
      placeholder: "10-digit Token",
      onKeyDown: eKeyPrevent,
    },
    password: {
      type: "password",
      autoComplete: "current-password",
      placeholder: "",
    },
    passwordRevealed: {
      type: "text",
      autoComplete: "current-password",
      placeholder: "Password",
    },
    newPassword: {
      type: "password",
      autoComplete: "new-password",
      placeholder: "",
    },
    newPasswordRevealed: {
      type: "text",
      autoComplete: "new-password",
      placeholder: "Password",
    },
    mfaCode: {
      type: "text",
      autoComplete: "off",
      placeholder: "",
    },
    readOnlyText: {
      type: "text",
      autoComplete: "off",
      placeholder: "",
    },
    userInfo: {
      type: "text",
      autoComplete: "off",
    },
  });

// biome-ignore lint/suspicious/noExplicitAny: TODO unknown 等に置き換える
const useTextInputState = (props: any) => {
  const {
    size,
    disabled,
    className,
    startAdornment,
    endAdornment,
    validationMessage,
    ...rest
  } = props;
  return { ...rest };
};

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      status = undefined,
      size,
      disabled,
      variant,
      withLabelTag,
      leadingIcon = "None",
      trailingIcon = "None",
      labelVariant,
      onIconClick,
      trailingIconLabel,
      className,
      hasValue,
      children,
      testId,
      ...rest
    },
    forwardedRef,
  ) => {
    const param = merge(params[variant], rest);
    const isDisabled: boolean = disabled || false;
    const isRequired = rest.required || false;

    const floatingIconComponent =
      leadingIcon !== "None" && labelVariant === "float"
        ? LeadingIcons[leadingIcon]
        : undefined;

    const leadingIconComponent =
      leadingIcon !== "None" && labelVariant === "head"
        ? () => <Text as="span">{LeadingIcons[leadingIcon]}</Text>
        : undefined;

    const trailingIconElement =
      trailingIcon !== "None"
        ? () => (
            <Box
              role="button"
              className="nid-input-field-action"
              onClick={onIconClick}
              aria-label={trailingIconLabel}
              title={trailingIconLabel}
              tabIndex={0}
              onKeyDown={(e: { key: string }) => {
                if (e.key === "Enter" && onIconClick) onIconClick();
              }}
            >
              {TrailingIcons[trailingIcon]}
            </Box>
          )
        : undefined;

    const state = useTextInputState(param);

    return (
      <FormControl
        disabled={isDisabled}
        required={isRequired}
        status={status}
        labelVariant={labelVariant}
        hasValue={hasValue || false}
      >
        <FormControl.Label>
          {floatingIconComponent}
          <Text as="span">{rest.label as string}</Text>
          {withLabelTag && (
            <Tag
              text={withLabelTag}
              variant={rest.required ? "error" : "default"}
            />
          )}
        </FormControl.Label>
        <InputField
          status={status}
          size={size}
          disabled={isDisabled}
          className={className}
          startAdornment={leadingIconComponent}
          endAdornment={trailingIconElement}
          ref={forwardedRef}
          data-testid={testId}
          containerClassName="mb-1"
          {...state}
        />
        {rest.helperText && (
          <FormControl.Validation status={undefined}>
            {rest.helperText}
          </FormControl.Validation>
        )}
        {children}
        <FormControl.Validation
          className="nid-error-field"
          status={status}
          data-testid={`error-${testId}`}
        >
          {rest.validationMessage as string}
        </FormControl.Validation>
      </FormControl>
    );
  },
) as React.ForwardRefExoticComponent<TextFieldProps>;

TextField.displayName = DISPLAY_NAME;
const Root = TextField;
export { Root, TextField };
export type { TextFieldProps };
export default TextField;
