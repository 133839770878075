import type React from "react";

import { DialogLayout } from "@/components/ui/Layout";
import { Typography } from "nikkei-ui";
import Button from "nikkei-ui/components/Buttons/Button";
import { useTranslation } from "react-i18next";

const FinishedPage: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <DialogLayout title={t("error.finished.title")}>
      <Typography>{t("error.finished.message")}</Typography>
      <Button
        className="mt-16"
        size="full"
        data-testid="step-finish-button"
        onClick={() => history.go(1)}
      >
        {t("error.finished.button")}
      </Button>
    </DialogLayout>
  );
};

export default FinishedPage;
