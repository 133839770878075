import { DialogLayout as NidDialogLayout } from "nid-common";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

type DialogLayoutProps = {
  title: string | "loading";
  testId?: string;
  children: React.ReactNode;
};

export const DialogLayout: React.FC<DialogLayoutProps> = ({
  title,
  children,
}) => {
  const { t } = useTranslation();
  const isHeadTitle = title && title !== "loading";
  return (
    <>
      {isHeadTitle && (
        <Helmet>
          <title>
            {title} | {t("site_name")}
          </title>
        </Helmet>
      )}
      <NidDialogLayout title={title}>{children}</NidDialogLayout>
    </>
  );
};
