import type React from "react";
import { useEffect } from "react";

import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";

import { InteractionIdState } from "@/contexts/RequestContext";
import { path } from "@/routes";

type Props = {
  type: "login" | "signup";
  children: React.ReactNode;
};

export const InteractionProvider: React.FC<Props> = ({ type, children }) => {
  const { interactionId } = useAtomValue(InteractionIdState);
  const navigate = useNavigate();

  useEffect(() => {
    if (interactionId === "") {
      navigate(type === "login" ? path.login.error : path.signup.error, {
        replace: true,
      });
    }
  }, [interactionId]);

  if (interactionId === "") {
    return <></>;
  }

  return <>{children}</>;
};
